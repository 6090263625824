import Keycloak, { KeycloakInitOptions } from "keycloak-js";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import axios, { AxiosInstance } from "axios";
import { baseUrl } from "./config";

const keycloakConfig = {
  url: "https://plan2plate.online/keycloak",
  realm: "plan2plate",
  clientId: "plan2plate-app",
};

const token = localStorage.getItem("token");
const refreshToken = localStorage.getItem("refreshToken");

export const keycloak = new Keycloak(keycloakConfig);

interface KeycloakContextProps {
  keycloak: typeof keycloak | null;
  authenticated: boolean;
}

export const KeycloakContext = createContext<KeycloakContextProps>({
  keycloak: null,
  authenticated: false,
});

export const KeycloakProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [keycloakInstance, setKeycloakInstance] = useState<
    typeof keycloak | null
  >(null);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (!keycloak.didInitialize) {
      const redirectUri = process.env.REDIRECT_URL;
      const doLogin = !!token && !!refreshToken;
      const kcOptions: KeycloakInitOptions = doLogin
        ? {
            onLoad: "check-sso",
            token: token,
            refreshToken: refreshToken,
            redirectUri: redirectUri,
          }
        : {
            onLoad: "login-required",
            redirectUri: redirectUri,
          };
      keycloak
        .init(kcOptions)
        .then((authenticated) => {
          setKeycloakInstance(keycloak);
          if (!authenticated) {
            keycloak.login();
          } else {
            setAuthenticated(authenticated);
            if (authenticated && doLogin) {
              localStorage.setItem("token", keycloak.token!);
              localStorage.setItem("refreshToken", keycloak.refreshToken!);
            }
          }
        })
        .catch((err) => {
          console.error("Failed to initialize Keycloak", err);
        });
    }
  }, []);

  useEffect(() => {
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            localStorage.setItem("token", keycloak.token!);
            localStorage.setItem("refreshToken", keycloak.refreshToken!);
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 60000); // Update token every minute
  }, []);

  return (
    <KeycloakContext.Provider
      value={{ keycloak: keycloakInstance, authenticated }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};

export interface LoadingContextType {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const LoadingContext = createContext<LoadingContextType | undefined>(
  undefined,
);

export const useLoading = (): LoadingContextType => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};

let setLoading: (loading: boolean) => void;

export const setLoadingFunction = (setter: (loading: boolean) => void) => {
  setLoading = setter;
};

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (keycloak && keycloak.token) {
      config.headers.Authorization = `Bearer ${keycloak.token}`;
    }
    if (setLoading) setLoading(true);
    return config;
  },
  (error) => {
    if (setLoading) setLoading(false);
    return Promise.reject(error);
  },
);
axiosInstance.interceptors.response.use(
  (response) => {
    if (setLoading) setLoading(false);
    return response;
  },
  (error) => {
    if (setLoading) setLoading(false);
    return Promise.reject(error);
  },
);
