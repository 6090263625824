import * as React from "react";
import { useEffect, useState } from "react";
import { Box, ChakraProvider, Grid, VStack } from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Logo } from "./Logo";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FoodSearchPage from "./pages/food-search-page";
import FoodCreatePage from "./pages/food-create-page";
import MealPlannerPage from "./pages/meal-planner-page";
import FoodEditPage from "./pages/food-edit-page";
import PurchaseCreatePage from "./pages/purchase-create-page";
import PurchaseEditPage from "./pages/purchase-edit-page";
import PurchaseSearchPage from "./pages/purchase-search-page";
import { DishCreatePage } from "./pages/dish-create-page";
import DishEditPage from "./pages/dish-edit-page";
import ShopEditPage from "./pages/shop-edit-page";
import DepartmentEditPage from "./pages/department-edit-page";
import DishSearchPage from "./pages/dish-search-page";
import ShoppingListCreatePage from "./pages/shopping-list-create-page";
import ShoppingListPage from "./pages/shopping-list-page";
import NeedEditPage from "./pages/need-edit-page";
import PersonEditPage from "./pages/person-edit-page";
import { PersonCreatePage } from "./pages/person-create-page";
import { Layout } from "./layout";
import axios, { AxiosError } from "axios";
import {
  axiosInstance,
  keycloak,
  KeycloakContext,
  KeycloakProvider,
  LoadingContext,
  setLoadingFunction,
} from "./keycloak";
import { PersonSearchPage } from "./pages/person-search-page";
import theme from "./theme/theme";
import StackTrace from "stacktrace-js";

axios.interceptors.response.use(
  //
  function (response) {
    // Optional: Do something with response data test
    return response;
  },
  function (error) {
    // Do whatever you want with the response error here:
    //

    // But, be SURE to return the rejected promise, so the caller still has
    // the option of additional specialized handling at the call-site:
    return Promise.reject(error);
  },
);

const Home = () => (
  <Box textAlign="center" fontSize="xl">
    <Grid minH="80vh" p={3}>
      <ColorModeSwitcher justifySelf="flex-end" />
      <VStack spacing={8}>
        <Logo h="40vmin" pointerEvents="none" />
      </VStack>
    </Grid>
  </Box>
);

function isAxiosError(error: Error): error is AxiosError {
  return error.name == "AxiosError";
}

export const App = () => {
  const postError = (msg: string) =>
    axiosInstance.post("/api/error", {
      error: msg,
    });

  useEffect(() => {
    const handleGlobalError = async (
      message: string | Event,
      source?: string,
      lineno?: number,
      colno?: number,
      error?: Error,
    ): Promise<boolean> => {
      if (error) {
        const stackframes = await StackTrace.fromError(error);
        const stackString = stackframes.map((sf) => sf.toString()).join("\n");
        postError(`JS-Error (${error.name}):${error.message}\n${stackString}`);
      } else {
        const errorInfo = JSON.stringify({
          message,
          source,
          lineno,
          colno,
          error,
        });
        postError(`JS-Error (unknown):\n${errorInfo}`);
      }
      return true;
    };

    const handleUnhandledRejection = async (
      event: PromiseRejectionEvent,
    ): Promise<void> => {
      const error =
        event.reason instanceof Error
          ? event.reason
          : new Error(String(event.reason));
      const stackframes = await StackTrace.fromError(error);
      const stackString = stackframes.map((sf) => sf.toString()).join("\n");

      if (isAxiosError(error) && error.response?.status == 401) {
        keycloak.login();
      } else {
        postError(`Unhandled Rejection:\n${stackString}`);
      }
    };

    window.onerror = handleGlobalError;
    window.addEventListener("unhandledrejection", handleUnhandledRejection);

    return () => {
      window.onerror = null;
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection,
      );
    };
  }, []);

  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoadingFunction(setLoading);
  }, [setLoading]);

  const pageContent = (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/food" element={<FoodSearchPage />} />
            <Route path="/food/create" element={<FoodCreatePage />} />
            <Route path="/food/edit/:foodId" element={<FoodEditPage />} />
            <Route path="/purchase" element={<PurchaseSearchPage />} />
            <Route
              path="/purchase/create/:foodId"
              element={<PurchaseCreatePage />}
            />
            <Route
              path="/purchase/edit/:purchaseId"
              element={<PurchaseEditPage />}
            />
            <Route path="/plan" element={<MealPlannerPage />} />
            <Route path="/dish" element={<DishSearchPage />} />
            <Route path="/dish/create" element={<DishCreatePage />} />
            <Route path="/dish/edit/:dishId" element={<DishEditPage />} />
            <Route path="/shop" element={<ShopEditPage />} />
            <Route path="/department" element={<DepartmentEditPage />} />
            <Route
              path="/shopping-list/create"
              element={<ShoppingListCreatePage />}
            />
            <Route path="/shopping-list" element={<ShoppingListPage />} />
            <Route path="/need" element={<NeedEditPage />} />
            <Route path="/person/edit/:personId" element={<PersonEditPage />} />
            <Route path="/person/create" element={<PersonCreatePage />} />
            <Route path="/person" element={<PersonSearchPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </LoadingContext.Provider>
  );
  const useKeycloak = !process.env.DISABLE_KEYCLOAK;
  return (
    <ChakraProvider theme={theme}>
      {useKeycloak ? (
        <KeycloakProvider>
          <KeycloakContext.Consumer>
            {({ keycloak, authenticated }) =>
              !keycloak ? (
                <div>Loading...</div>
              ) : !authenticated ? (
                <div>
                  Unauthorized
                  <button
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Seite neu laden
                  </button>
                </div>
              ) : (
                pageContent
              )
            }
          </KeycloakContext.Consumer>
        </KeycloakProvider>
      ) : (
        pageContent
      )}
    </ChakraProvider>
  );
};
