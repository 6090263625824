import React, { useRef } from "react";
import { Plan2PlateIconButton } from "./buttons/plan2-plate-icon-button";
import { Input } from "@chakra-ui/react";
import { axiosInstance } from "../keycloak";
import Food from "../data/food";
import { plan2plateIcons } from "../utils/icon-utils";
import { Purchase } from "../data/purchase";

function ImageScanner(props: {
  icon: keyof typeof plan2plateIcons;
  fileScanned: (file: File) => void | Promise<void>;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <Plan2PlateIconButton
        icon={props.icon}
        size={"sm"}
        title={props.icon}
        aria-label={props.icon}
        onClick={() => inputRef.current?.click()}
      />
      <Input
        ref={inputRef}
        display={"none"}
        type={"file"}
        capture={"environment"}
        onChange={async (e) => {
          const files = e.target.files;
          if (files) {
            for (let i = 0; i < files.length; i++) {
              const file = files.item(i);
              if (file) {
                props.fileScanned(file);
              }
            }
          }
        }}
      />
    </>
  );
}

export const LabelScanner = (props: {
  onLabelScan?: (value: Food) => void;
}) => {
  return (
    <ImageScanner
      icon={"clipboardImage"}
      fileScanned={async (file) => {
        const formData = new FormData();
        formData.set("file", file);
        const result = (
          await axiosInstance.post<Food>(`/api/scanLabel`, formData)
        ).data;
        props.onLabelScan && props.onLabelScan(result);
      }}
    />
  );
};

export const PurchaseLabelScanner = (props: {
  onLabelScan?: (value: Purchase) => void;
}) => {
  return (
    <ImageScanner
      icon={"clipboardImage"}
      fileScanned={async (file) => {
        const formData = new FormData();
        formData.set("file", file);
        const result = (
          await axiosInstance.post<Purchase>(`/api/scanPurchaseLabel`, formData)
        ).data;
        props.onLabelScan && props.onLabelScan(result);
      }}
    />
  );
};

export const BarcodeScanner = (props: {
  onBarcodeScan?: (value: number | undefined) => void;
}) => {
  return (
    <ImageScanner
      icon={"barcode"}
      fileScanned={async (file) => {
        const formData = new FormData();
        formData.set("file", file);
        const result = (await axiosInstance.post(`/api/scan`, formData)).data;
        const eanNumber = Number(result);
        const ean = isNaN(eanNumber) ? undefined : eanNumber;
        props.onBarcodeScan && props.onBarcodeScan(ean);
      }}
    />
  );
};
