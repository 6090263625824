import Food, { BaseUnit } from "../data/food";
import { FoodUnit } from "../data/food-unit";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  forwardRef,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { StringInput } from "./string-input";
import { getLowerCaseBaseUnit, NutritionInput } from "./nutrition-input";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useUpdate } from "../utils/use-update";
import { useNavigate } from "react-router-dom";
import { isDeleted, isNew } from "../utils/edit-utils";
import { NumberInput } from "./number-input";
import { computeCalories } from "../utils/calories-util";
import { getRenderKey } from "../utils/render-utils";
import { map, merge, values } from "lodash";
import { SaveButton } from "./buttons/save-button";
import { Plan2PlateIconButton } from "./buttons/plan2-plate-icon-button";
import { AvailabilityButton } from "./buttons/availability-button";
import { DeleteButton } from "./buttons/delete-button";
import { axiosInstance } from "../keycloak";
import { PurchaseLookupContext } from "../contexts";
import { formatPurchaseDescription } from "../utils/purchase-utils";
import { SearchResultCard } from "./search-result-card";
import { CancelButton } from "./buttons/cancel-button";
import { useUnloadAlert } from "./use-unload-alert";
import { deepEquals } from "../utils/equal-utils";
import { showValidationMessage } from "./show-validation-message";
import { canBeDeleted } from "../utils/entity-utils";
import {
  AvailabilityDrawer,
  useAvailabilityDrawer,
} from "./availability-drawer";
import { LabelScanner } from "./barcode-scanner";

interface FoodUnitProps {
  foodUnit: FoodUnit;
  onRemove: () => void;
  onUpdate: (updatedUnit: FoodUnit) => void;
  baseUnit: BaseUnit;
  disabled: boolean;
  food?: Food;
}

function hasInvalidDescription(editedUnit: FoodUnit) {
  return editedUnit.description.length > 10;
}

const FoodUnitsInput = forwardRef<FoodUnitProps, any>(
  ({ foodUnit, onRemove, onUpdate, disabled, baseUnit, food }, ref) => {
    const {
      editedObject: editedUnit,
      updateEditedObjectField: updateEditedUnitField,
    } = useUpdate(foodUnit);

    const handleBlur = (newValue: FoodUnit) => {
      onUpdate(newValue);
    };

    const boxTitle = editedUnit.description
      ? `${editedUnit.description}`
      : "Neue Portion";
    return (
      <Box borderWidth="1px" borderRadius="lg" padding="4" boxShadow="md">
        <FormControl>
          <Stack spacing="4">
            <FormLabel htmlFor={`portion-${foodUnit.id}`} fontSize="lg">
              {boxTitle}
            </FormLabel>
            {food?.purchaseRelevant && (
              <Checkbox
                colorScheme="teal"
                paddingRight="20px"
                isChecked={foodUnit.isDefault}
                onChange={(e) => {
                  const newValue = updateEditedUnitField(
                    "isDefault",
                    e.target.checked,
                  );
                  handleBlur(newValue);
                }}
              >
                Einkauf-Einheit
              </Checkbox>
            )}
            <StringInput
              ref={ref}
              isInvalid={hasInvalidDescription(editedUnit)}
              fieldName="description"
              fieldDescription="Bezeichnung"
              value={editedUnit.description}
              updater={updateEditedUnitField}
              width={"100%"}
              handleBlur={handleBlur}
              disabled={foodUnit.isAutoCreate}
            />
            <HStack>
              <NumberInput
                fieldName="netWeight"
                fieldDescription="Netto"
                fieldUnit={getLowerCaseBaseUnit(baseUnit)}
                value={editedUnit.netWeight}
                updater={updateEditedUnitField}
                handleBlur={handleBlur}
                disabled={disabled || foodUnit.isAutoCreate}
              />
              <NumberInput
                fieldName="grossWeight"
                fieldDescription="Brutto"
                fieldUnit={getLowerCaseBaseUnit(baseUnit)}
                value={editedUnit.grossWeight}
                updater={updateEditedUnitField}
                handleBlur={handleBlur}
                disabled={disabled || foodUnit.isAutoCreate}
              />
            </HStack>
            <HStack justifyContent={"center"}>
              {canBeDeleted(editedUnit) && <DeleteButton onClick={onRemove} />}
            </HStack>
          </Stack>
        </FormControl>
      </Box>
    );
  },
);

export type FoodEditorProps = {
  initialFood: Food;
  isCreate: boolean;
  afterSave?: (createdFood: Food) => void | Promise<void>;
};

function hasDefaultUnit(editedFood: Food | undefined) {
  return editedFood?.foodUnits.find((fu) => fu.isDefault) != undefined;
}

export const FoodEditor = (props: FoodEditorProps) => {
  const { initialFood, isCreate, afterSave } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const [nutriValuesPerPortion, setNutriValuesPerPortion] =
    useState<boolean>(false);

  const {
    editedObject: editedFood,
    updateEditedObjectField: updateEditedFoodField,
    updateEditedObject: updateEditedFood,
  } = useUpdate<Food>(initialFood);

  const purchaseLookup = useContext(PurchaseLookupContext);

  const purchases = useMemo(
    () => values(purchaseLookup || {}).filter((p) => p.foodId == editedFood.id),
    [purchaseLookup],
  );

  const defaultPurchase = purchases.find((p) => p.isDefault);

  const availabilityDrawerProps = useAvailabilityDrawer(
    async (availabilities) => {
      updateEditedFood((foodDraft) => {
        foodDraft.availabilities = availabilities.filter((a) => !isDeleted(a));
      });
      return false;
    },
    false,
  );

  const addFoodUnit = () => {
    updateEditedFood((foodDraft) => {
      foodDraft.foodUnits.push({
        id: -1,
        description: "",
        netWeight: 0,
        grossWeight: 0,
        isDefault: false,
      });
    });
  };

  const addPredefinedFoodUnit = (amount: 1 | 100, unit: "g" | "ml") => () => {
    updateEditedFood((foodDraft) => {
      foodDraft.foodUnits.push({
        id: -1,
        description: `${amount == 1 ? "" : amount}${unit}`,
        netWeight: amount,
        grossWeight: amount,
        isDefault: false,
        isAutoCreate: true,
      });
    });
  };

  const hasFoodUnitWithAmount = (amount: number) =>
    editedFood.foodUnits.find((fu) => fu.netWeight == amount) != undefined;

  const removeFoodUnit = (index: number) => {
    updateEditedFood((foodDraft) => {
      foodDraft.foodUnits.splice(index, 1);
    });
  };

  const updateFoodUnit = (updatedFoodUnit: FoodUnit, foodUnitIndex: number) => {
    updateEditedFood((foodDraft) => {
      foodDraft.foodUnits[foodUnitIndex] = updatedFoodUnit;
    });
  };

  const addPurchase = () => {
    navigate(`/purchase/create/${editedFood.id}`);
  };

  const save = async () => {
    if (!hasDefaultUnit(editedFood) && editedFood.purchaseRelevant) {
      showValidationMessage(toast, "Keine Einkauf-Einheit definiert");
      return false;
    } else if (
      editedFood.purchaseRelevant &&
      editedFood.availabilities.length == 0
    ) {
      toast({
        title: "Keine Verfügbarkeiten definiert",
        status: "error",
        isClosable: true,
      });
      return false;
    } else {
      const foodUnitsWithInvalidDescription = editedFood.foodUnits.filter(
        hasInvalidDescription,
      );
      if (foodUnitsWithInvalidDescription.length > 0) {
        const descriptions = map(
          foodUnitsWithInvalidDescription,
          "description",
        );
        toast({
          title: `Folgende Einheiten haben eine zu lange Bezeichnung (>10): ${descriptions.join()}`,
          status: "error",
          isClosable: true,
        });
        return false;
      } else {
        const foodUnitsWithInvalidWeight = editedFood.foodUnits.filter(
          (fu) => (fu.netWeight || 0) <= 0 || (fu.grossWeight || 0) <= 0,
        );
        if (foodUnitsWithInvalidWeight.length) {
          const descriptions = map(foodUnitsWithInvalidWeight, "description");
          toast({
            title: `Folgende Einheiten haben ein ungültiges Gewicht: ${descriptions.join()}`,
            status: "error",
            isClosable: true,
          });
          return false;
        }
        let factor: number | undefined;
        if (nutriValuesPerPortion) {
          factor = editedFood.foodUnits.find(
            (fu) => fu.description == "Portion",
          )?.netWeight;
          if (!factor) {
            toast({
              title: "Portion für Nährwerte ist nicht definiert",
              status: "error",
              isClosable: true,
            });
            return false;
          }
        } else {
          factor = 100;
        }
        const foodForSave = {
          ...editedFood,
          fat: (editedFood.fat * 100) / factor,
          carbs: (editedFood.carbs * 100) / factor,
          protein: (editedFood.protein * 100) / factor,
          kcal: editedFood.kcal ? (editedFood.kcal * 100) / factor : undefined,
        };
        const axiosResponse = isCreate
          ? await axiosInstance.post<Food>(`/api/food`, foodForSave)
          : await axiosInstance.put<Food>(`/api/food`, foodForSave);
        afterSave && afterSave(axiosResponse.data);
        return true;
      }
    }
  };

  const { pristine, setPristine } = useUnloadAlert();

  useEffect(() => {
    setPristine(deepEquals(initialFood, editedFood));
  }, [editedFood]);

  const handleBlur = () => {};

  const editAvailabilities = () => {
    availabilityDrawerProps.editAvailabilities(
      editedFood.foodUnits.find((fu) => fu.isDefault)?.id || -1,
      editedFood.availabilities,
    );
  };

  const doComputeCalories = () => {
    const calories = computeCalories(editedFood);
    updateEditedFoodField("kcal", calories);
  };

  const unit = getLowerCaseBaseUnit(editedFood.baseUnit);

  const newItemRef = useRef<any>(null);

  useEffect(() => {
    if (newItemRef.current) {
      newItemRef.current.focus();
    }
  }, [editedFood.foodUnits.length]);

  const updateNutriValuesPerPortion = (checked: boolean) => {
    setNutriValuesPerPortion(checked);
    updateEditedFood((foodDraft) => {
      if (checked) {
        let portion = foodDraft.foodUnits.find(
          (fu) => fu.description == "Portion",
        );
        if (!portion) {
          portion = {
            id: -1,
            description: "Portion",
            netWeight: 0,
            grossWeight: 0,
            isDefault: true,
            isAutoCreate: false,
          };
          foodDraft.foodUnits.push(portion);
        }
        portion.isDefault = true;
        for (const foodUnit of foodDraft.foodUnits) {
          if (foodUnit != portion) {
            foodUnit.isDefault = false;
          }
        }
      }
    });
  };

  const baseline = nutriValuesPerPortion ? "pro Portion" : `pro 100${unit}`;

  const { isOpen, onClose, onOpen } = useDisclosure();
  const leastDestructiveRef = useRef<HTMLButtonElement>(null);
  const deleteFood = () => {
    onOpen();
  };

  const nonDefaultPurchases = purchases.filter((p) => !p.isDefault);
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={leastDestructiveRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Wirklich löschen?
            </AlertDialogHeader>
            <AlertDialogBody></AlertDialogBody>
            <AlertDialogFooter>
              <Flex justify="flex-end" paddingTop="10px" gap={3}>
                <CancelButton onClick={onClose} />
                <DeleteButton
                  onClick={async () => {
                    await axiosInstance.delete(`/api/food/${editedFood.id}`);
                    onClose();
                    navigate(`/food`);
                  }}
                  ref={leastDestructiveRef}
                />
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AvailabilityDrawer {...availabilityDrawerProps} />
      <VStack className="stretch-stack">
        <Stack spacing="4">
          <HStack>
            <StringInput
              width={"100%"}
              fieldName="description"
              leftAddonMinW="200px"
              value={editedFood.description}
              updater={updateEditedFoodField}
              handleBlur={handleBlur}
              fieldDescription="Bezeichnung"
            />
            <LabelScanner
              onLabelScan={(food) => {
                const mergedFood = merge({}, editedFood, food);
                mergedFood.purchaseRelevant = true;
                updateEditedFood(mergedFood);
              }}
            />
          </HStack>
          <Checkbox
            colorScheme="teal"
            paddingRight="20px"
            isChecked={editedFood.baseUnit == "ML"}
            onChange={(e) => {
              updateEditedFoodField("baseUnit", e.target.checked ? "ML" : "G");
            }}
          >
            Ist in ml
          </Checkbox>
          <>
            {isCreate && (
              <Checkbox
                disabled={!isCreate}
                colorScheme="teal"
                paddingRight="20px"
                isChecked={editedFood.purchaseRelevant}
                onChange={(e) => {
                  updateEditedFoodField("purchaseRelevant", e.target.checked);
                }}
              >
                Einkaufsliste-Relevant
              </Checkbox>
            )}
            {!editedFood.purchaseRelevant && (
              <StringInput
                width={"100%"}
                fieldName="vendor"
                leftAddonMinW="200px"
                value={editedFood.vendor || ""}
                updater={updateEditedFoodField}
                handleBlur={handleBlur}
                fieldDescription="Lokal"
              />
            )}
            {isCreate && (
              <Checkbox
                colorScheme="teal"
                onChange={(e) => updateNutriValuesPerPortion(e.target.checked)}
                isChecked={nutriValuesPerPortion}
              >
                Nährwerte pro Portion
              </Checkbox>
            )}
          </>
          )
          <Box className="relative-flex-editor-box">
            <Text
              position={"absolute"}
              top={"-10px"}
              fontSize={"xs"}
              backgroundColor={"var(--chakra-colors-chakra-body-bg)"}
            >
              Nährwerte {baseline}
            </Text>
            <VStack alignItems={"start"}>
              <HStack>
                <NutritionInput
                  fieldName="kcal"
                  value={editedFood.kcal}
                  updater={updateEditedFoodField}
                  handleBlur={handleBlur}
                  fieldDescription="Kalorien"
                  disabled={!isCreate}
                />
                {!isCreate && defaultPurchase && (
                  <Plan2PlateIconButton
                    aria-label={"editDefaultPurchase"}
                    title={"Standard-Produkt bearbeiten"}
                    icon={"pencil"}
                    onClick={() => {
                      navigate(`/purchase/edit/${defaultPurchase.id}`);
                    }}
                  />
                )}
                {isCreate && (
                  <Plan2PlateIconButton
                    aria-label={"computeCalories"}
                    title={"Kcal berechnen"}
                    icon={"calculator"}
                    onClick={doComputeCalories}
                  />
                )}
              </HStack>
              <HStack flexWrap={"wrap"}>
                <NutritionInput
                  fieldName="fat"
                  value={editedFood.fat}
                  updater={updateEditedFoodField}
                  handleBlur={handleBlur}
                  fieldDescription="Fett"
                  disabled={!isCreate}
                />
                <NutritionInput
                  fieldName="carbs"
                  value={editedFood.carbs}
                  updater={updateEditedFoodField}
                  handleBlur={handleBlur}
                  fieldDescription="KH"
                  disabled={!isCreate}
                />
                <NutritionInput
                  fieldName="protein"
                  value={editedFood.protein}
                  updater={updateEditedFoodField}
                  handleBlur={handleBlur}
                  fieldDescription="Protein"
                  disabled={!isCreate}
                />
              </HStack>
            </VStack>
          </Box>
          {editedFood.foodUnits.map((foodUnit, foodUnitIndex) => (
            <FoodUnitsInput
              food={editedFood}
              key={getRenderKey(foodUnit, foodUnitIndex)}
              disabled={!isNew(foodUnit)}
              foodUnit={foodUnit}
              onRemove={() => removeFoodUnit(foodUnitIndex)}
              onUpdate={(foodUnit) => updateFoodUnit(foodUnit, foodUnitIndex)}
              baseUnit={editedFood.baseUnit}
              ref={
                foodUnitIndex == editedFood.foodUnits.length - 1
                  ? newItemRef
                  : undefined
              }
            />
          ))}
          {!isCreate && (
            <Accordion allowToggle={true} width={"100%"}>
              <AccordionItem>
                <AccordionButton>
                  <Box
                    as="span"
                    flex={1}
                    textAlign={"left"}
                    fontWeight={"bold"}
                  >
                    {"Produkte"}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel motionProps={{ animateOpacity: false }}>
                  <VStack>
                    <Flex flexWrap={"wrap"} width={"100%"}>
                      {nonDefaultPurchases.map((purchase) => {
                        return (
                          <SearchResultCard
                            key={purchase.id}
                            onClick={() =>
                              navigate(`/purchase/edit/${purchase.id}`)
                            }
                          >
                            {formatPurchaseDescription(purchase)}
                          </SearchResultCard>
                        );
                      })}
                    </Flex>
                    {!isCreate && (
                      <Plan2PlateIconButton
                        aria-label={"addPurchase"}
                        title={"Produkt erstellen"}
                        icon={"purchase"}
                        onClick={addPurchase}
                      />
                    )}
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <HStack width={"100%"} justifyContent={"center"}>
            <Plan2PlateIconButton
              aria-label={"addPredefinedFoodUnit"}
              title={`1${unit} hinzufügen`}
              isDisabled={hasFoodUnitWithAmount(1)}
              icon={"unit_1"}
              onClick={addPredefinedFoodUnit(1, unit)}
            />
            <Plan2PlateIconButton
              aria-label={"addPredefinedFoodUnit"}
              title={`100${unit} hinzufügen`}
              isDisabled={hasFoodUnitWithAmount(100)}
              icon={"unit_100"}
              onClick={addPredefinedFoodUnit(100, unit)}
            />
            <Plan2PlateIconButton
              aria-label={"addFoodUnit"}
              title={"Einheit hinzufügen"}
              icon={"unit"}
              onClick={addFoodUnit}
            />
            {isCreate && <AvailabilityButton onClick={editAvailabilities} />}
            {editedFood.canBeDeleted && !isNew(editedFood) && (
              <DeleteButton onClick={deleteFood} />
            )}
            <SaveButton isDisabled={pristine} save={save} />
          </HStack>
        </Stack>
      </VStack>
    </>
  );
};
